<template>
    <div class="bg-white shadow-lg rounded-lg mr-3 ml-3 pb-5 mt-5 relative">
        <LoadingComponent :isLoading="loading" />
        <div class="content">
            <div class="top-content">
                <div class="title text-black-blod-18">Banner 資訊</div>
                <div class="inputs">
                    <div class="input">
                        <div class="label">Banner 名稱</div>
                        <el-input v-model="inputs.title" clearable> </el-input>
                    </div>
                    <div class="input">
                        <div class="label">啟用狀態</div>
                        <el-select v-model="inputs.activated" class="w-full" placeholder="請選擇">
                            <el-option v-for="(item, idx) in openOption" :key="idx" :label="item.label" :value="item.value" />
                        </el-select>
                    </div>
                    <div class="input">
                        <div class="label">位置</div>
                        <el-select v-model="inputs.category" class="w-full" placeholder="請選擇">
                            <el-option v-for="(item, idx) in categoryOption" :key="idx" :label="item.label" :value="item.value" />
                        </el-select>
                    </div>
                    <div class="input">
                        <div class="label">使用期間</div>
                        <el-select v-model="isDuring" class="w-full" placeholder="请选择">
                            <el-option v-for="(item, idx) in duringOption" :key="idx" :label="item.label" :value="item.value" />
                        </el-select>
                    </div>
                    <div class="input">
                        <div class="label">目標網址</div>
                        <el-input v-model="inputs.target" clearable> </el-input>
                    </div>
                    <div class="input">
                        <div class="label">期間範圍</div>
                        <el-date-picker
                            v-model="inputDates"
                            style="width: 100%"
                            type="daterange"
                            value-format="yyyy-MM-dd"
                            range-separator="至"
                            start-placeholder="開始日期"
                            end-placeholder="结束日期"
                            :disabled="isDuring === false">
                        </el-date-picker>
                    </div>
                </div>
            </div>
            <div class="bottom-content">
                <div class="title text-black-blod-18">Banner 圖片</div>
                <div class="upload-area">
                    <div class="left">
                        <div class="label">電腦版（上傳尺寸限制：{{ imageLimit.desktop.width }}*{{ imageLimit.desktop.height }}）</div>
                        <el-upload
                            action="#"
                            :limit="1"
                            :auto-upload="false"
                            accept=".jpg, .jpeg, .png"
                            :on-change="changeFiledDesktop"
                            list-type="picture"
                            :file-list="fileList1">
                            <button v-if="!imgObj1.url" class="orange-btn-outline-800 md:text-sm text-xs mr-1 w-[113px] h-[35px]">上傳圖片</button>
                            <div slot="file"></div>
                        </el-upload>
                        <div v-if="imgObj1.url" class="display-img">
                            <div class="error" @click="handleRemove(1)">
                                <i class="fas fa-times"></i>
                            </div>
                            <img :src="imgObj1.url" />
                        </div>
                    </div>
                    <div class="right">
                        <div class="label">手機版（上傳尺寸限制：{{ imageLimit.mobile.width }}*{{ imageLimit.mobile.height }}）</div>
                        <el-upload
                            action="#"
                            :limit="1"
                            :auto-upload="false"
                            :on-change="changeFileMobile"
                            accept=".jpg, .jpeg, .png"
                            list-type="picture"
                            :file-list="fileList2">
                            <button v-if="!imgObj2.url" class="orange-btn-outline-800 md:text-sm text-xs mr-1 w-[113px] h-[35px] flex-1">
                                上傳圖片
                            </button>
                        </el-upload>
                        <div v-if="imgObj2.url" class="display-img">
                            <div class="error" @click="handleRemove(2)">
                                <i class="fas fa-times"></i>
                            </div>
                            <img :src="imgObj2.url" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="actions">
            <button class="black-btn-outline-lg text-md mr-5" @click="goBack">返回列表</button>
            <button v-permission="['create']" class="orange-btn-800-lg text-md" @click="create">儲存變更</button>
        </div>
    </div>
</template>

<script>
// 旋轉動畫組件
import LoadingComponent from "@/components/Loading.vue";
// base64 轉 Blob
import { dataURLtoBlob } from "@/service/imageTransfer.js";
// handle api 錯誤訊息
import { handleErrorMessage } from "../components/errorMessage";
// 預設 image 暫存物件
const defaultImg = {
    url: "",
    file: null,
    base64: "",
};
export default {
    name: "AddAreaBanner",
    components: {
        LoadingComponent,
    },
    computed: {
        // 啟用選項
        openOption() {
            return [
                { label: "啟用", value: true },
                { label: "停用", value: false },
            ];
        },
        // 使用期間選項
        duringOption() {
            return [
                { label: "不限使用期間", value: false },
                { label: "限定使用期間", value: true },
            ];
        },
        // 位置選項
        categoryOption() {
            return [
                { label: "上方", value: 3 },
                { label: "下方", value: 4 },
            ];
        },
    },
    data() {
        return {
            loading: false,
            disabled: false,
            id: "",
            // 輸入匡狀態
            inputs: {
                name: "",
                category: 3,
                activated: true,
                target: "",
                title: "",
                start: "",
                end: "",
            },
            // 額外處理輸入
            isDuring: false,
            inputDates: [],
            // 畫面呈現 image 暫存物件
            imgObj1: { ...defaultImg }, // 桌機版圖片
            fileList1: [],
            imgObj2: { ...defaultImg }, // 手機版圖片
            fileList2: [],
            imageLimit: {
                desktop: {
                    width: [1200, 1200 * 2, 1200 * 3],
                    height: [150, 150 * 2, 150 * 3],
                },
                mobile: {
                    width: [355, 355 * 2, 355 * 3],
                    height: [90, 90 * 2, 90 * 3],
                },
            },
        };
    },
    methods: {
        // 刪除圖片
        handleRemove(num) {
            if (num === 1) {
                this.imgObj1 = { ...defaultImg };
                this.fileList1 = [];
            } else {
                this.imgObj2 = { ...defaultImg };
                this.fileList2 = [];
            }
        },
        // 更新桌機圖片
        changeFiledDesktop(file, fileList) {
            if (file.size / (1024 * 1024) > 5) {
                this.fileList1 = [];
                alert("檔案大小超出5mb");
                return;
            }
            this.fileList1 = fileList.slice(-3);
            this.imgObj1.url = file.url;
            this.imgObj1.file = file.raw;
            const reader = new FileReader();
            // 讀取上傳檔案
            reader.readAsDataURL(file.raw);
            // 將上傳檔案轉為base64
            reader.onload = () => {
                this.imgObj1.base64 = reader.result;
                // 確認 圖片 size
                this.checkImageLimit(this.imgObj1.base64, this.imageLimit.desktop, 1);
            };
        },
        // 更新手機圖片
        changeFileMobile(file, fileList) {
            if (file.size / (1024 * 1024) > 5) {
                this.fileList2 = [];
                alert("檔案大小超出5mb");
                return;
            }
            this.fileList2 = fileList.slice(-3);
            this.imgObj2.url = file.url;
            this.imgObj2.file = file.raw;
            const reader = new FileReader();
            // 讀取上傳檔案
            reader.readAsDataURL(file.raw);
            // 將上傳檔案轉為base64
            reader.onload = () => {
                this.imgObj2.base64 = reader.result;
                this.checkImageLimit(this.imgObj2.base64, this.imageLimit.mobile, 2);
            };
        },
        // 確認圖片寬高
        checkImageLimit(base64, limit, type) {
            var img = new Image();
            img.src = base64;
            img.onload = () => {
                if (!limit.height.includes(img.height) || !limit.width.includes(img.width)) {
                    if (type == 1) {
                        this.fileList1 = [];
                        this.imgObj1 = { ...defaultImg };
                    } else {
                        this.fileList2 = [];
                        this.imgObj2 = { ...defaultImg };
                    }
                    alert("圖片不符合寬高限制");
                }
            };
        },
        // 新增 API
        create() {
            const { end, start, title, activated, target, category } = this.inputs;
            if (this.isDuring) {
                if (!start || !end) {
                    alert("使用期間未填寫完整");
                    return;
                }
            }
            if (!this.imgObj1.url || !this.imgObj2.url) {
                alert("圖片未正確填入");
                return;
            }
            this.loading = true;
            const result = new FormData();

            result.append("title", title);
            result.append("target", target);
            result.append("position", "body");
            result.append("category", category);
            result.append("duration", this.isDuring ? 1 : 0);
            if (this.isDuring) {
                result.append("start", start);
                result.append("end", end);
            }

            // base64 轉 blob 格式
            if (this.imgObj1.base64) {
                const blob = dataURLtoBlob(this.imgObj1.base64);
                result.append("dimage", blob);
            }

            if (this.imgObj2.base64) {
                const blob = dataURLtoBlob(this.imgObj2.base64);
                result.append("mimage", blob);
            }

            result.append("activated", activated ? 1 : 0);

            this.$api
                .CreateExpoBanner(this.id, result)
                .then(() => {
                    this.$message({
                        type: "success",
                        message: "新增成功",
                    });
                    this.goBack();
                })
                .catch((err) => {
                    const errMessage = err.response.data.errors ? handleErrorMessage(err.response.data.errors) : "新增失敗";
                    this.$message({
                        type: "error",
                        message: errMessage,
                    });
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        // 返回列表
        goBack() {
            this.$router.push({ path: "/homepage_setting/area_banners" });
        },
    },
    watch: {
        // 監聽 element-ui 日期輸入匡，將值設定於輸入匡變數中
        inputDates(val) {
            if (val === null || val.length === 0) {
                this.inputs.start = "";
                this.inputs.end = "";
                return;
            }
            this.inputs.start = val[0];
            this.inputs.end = val[1];
        },
    },
};
</script>

<style lang="scss" scoped>
.myTable {
    width: 100%;
}
.content {
    .title {
        padding-left: 20px;
    }
    .top-content {
        padding: 30px 5% 0;
        .inputs {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            padding: 20px 20px 30px;
            border-bottom: 1px solid #757575;
            .input {
                width: 48%;
                padding: 10px 0px;
            }
        }
    }
    .bottom-content {
        padding: 30px 5%;
        .upload-area {
            display: flex;
            padding: 20px 20px 30px;
            .left {
                flex: 1;
                margin-right: 30px;
            }
            .right {
                flex: 1;
            }
            img {
                width: 100%;
            }
        }
    }
    .label {
        color: rgb(133, 130, 130);
        margin-bottom: 5px;
        font-size: 14px;
    }
}

.display-img {
    margin-top: 15px;
    position: relative;
    padding: 30px;
    .error {
        width: 24px;
        height: 24px;
        background: #000;
        border-radius: 50%;
        position: absolute;
        top: 20px;
        right: 20px;
        color: white;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
    }
}
::v-deep .el-upload-list {
    display: none;
}
.actions {
    width: 100%;
    display: flex;
    justify-content: center;
}
</style>
